import React from 'react'
import { Wrapper, Divider } from '@farewill/ui'

import DefaultLayout from 'layouts/DefaultLayout'
import Pricing from 'views/wills/components/Pricing'
import GetStartedOnline from 'views/wills/components/GetStartedOnline'
import { WILLS } from 'lib/products/constants'

import Hero from './Hero'
import Reasons from './Reasons'
import Steps from './Steps'

const ForCouples = () => (
  <DefaultLayout
    title="Joint & Mirror Wills | Married Couple & Cohabiting Partner"
    description="Looking to get wills for you and your partner? Make a solicitor-quality will online for half the price you would pay a solicitor. The UK’s number one will writer, consistently rated 5* by TrustPilot. Our friendly specialists are available 7 days a week to help and each will is legally double checked."
    product={WILLS}
  >
    <Hero />

    <Divider container />

    <Wrapper container>
      <Reasons />
    </Wrapper>

    <Divider container />

    <Wrapper container containerPaddingBottom={0}>
      <Steps />
    </Wrapper>

    <Wrapper container containerPaddingTop="L" containerPaddingBottom="L">
      <Pricing couples />
    </Wrapper>

    <Divider container />

    <Wrapper container>
      <GetStartedOnline />
    </Wrapper>
  </DefaultLayout>
)

export default ForCouples
