import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Grid, Image, BackgroundImageWrapper, H, P } from '@farewill/ui'
import { GTR, FONT, COLOR } from '@farewill/ui/tokens'
import { screen, screenMin } from '@farewill/ui/helpers/responsive'

import PreventOrphan from 'components/PreventOrphan'

const StyledListWrapper = styled(Grid)`
  ${screen.m`
    max-width: 700px
  `};
`

const StyledItemGrid = styled(Grid)`
  align-items: center;
  height: 100%;
`

const StyledGridItem = styled(Grid.Item)`
  align-self: self-end;
`

const StyledCounter = styled(BackgroundImageWrapper)`
  display: inline-block;
  content: counter(steps);
  width: 48px;
  height: 48px;
  margin: 0 0 ${GTR.S};
  font-size: ${FONT.SIZE.L};
  font-family: ${FONT.FAMILY.DECORATIVE};
  text-align: center;
  line-height: 48px;
  color: ${COLOR.BLACK};
`

const StyledItemIllustration = styled(Image)`
  max-width: 300px;
  margin: -20px 0;

  ${screenMin.s`
    max-width: 250px;
  `};
`

const StyledItemTitle = styled(H)`
  margin: 0 0 ${GTR.XS};

  ${screenMin.m`
    margin: 0 auto ${GTR.S};
    font-size: ${FONT.SIZE.L};
  `};
`

const CounteredList = ({ title, steps, titleSize, centered, decorative }) => (
  <StyledListWrapper
    gap={['L', 0]}
    gapFromM="L"
    gapFromL={['XL', 'L']}
    gapFromXL={['XXL', 'XL']}
    centered={centered}
    centeredFromM={false}
    centeredFromL={centered}
  >
    <Grid.Item>
      <H size={titleSize} decorative>
        <PreventOrphan>{title}</PreventOrphan>
      </H>
    </Grid.Item>

    {steps.map((step, index) => (
      /**
       * We can reasonably expect this to be static, so there is no danger of
       * unnecessary renders.
       */
      // eslint-disable-next-line react/no-array-index-key
      <Grid.Item spanFromL={12 / steps.length} key={index}>
        <StyledItemGrid gap={0} gapFromM="M">
          {step.illustration && (
            <Grid.Item spanFromM={5} spanFromL={12}>
              <StyledItemIllustration
                path={`illustrations/${step.illustration}`}
                width={300}
                widthFromM={250}
                widthFromL={300}
                stretch
              />
            </Grid.Item>
          )}

          <StyledGridItem spanFromM={step.illustration ? 7 : 12} spanFromL={12}>
            <StyledCounter
              imagePath="textures/yellow-dot"
              imageWidth={48}
              cover
            >
              {index + 1}
            </StyledCounter>

            {step.title && (
              <StyledItemTitle size="M" decorative={decorative}>
                <PreventOrphan>{step.title}</PreventOrphan>
              </StyledItemTitle>
            )}

            <P
              maxWidth={360}
              margin={centered ? [0, 'auto'] : 0}
              marginFromM={0}
            >
              <PreventOrphan>{step.description}</PreventOrphan>
            </P>
          </StyledGridItem>
        </StyledItemGrid>
      </Grid.Item>
    ))}
  </StyledListWrapper>
)

CounteredList.propTypes = {
  title: PropTypes.string,
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      description: PropTypes.string.isRequired,
      illustration: PropTypes.string,
    })
  ).isRequired,
  titleSize: PropTypes.string,
  centered: PropTypes.bool,
  decorative: PropTypes.bool,
}

CounteredList.defaultProps = {
  title: '',
  titleSize: 'L',
  centered: true,
  decorative: true,
}

export default CounteredList
