import React from 'react'
import styled from 'styled-components'
import { Grid, Wrapper, Image, H, P } from '@farewill/ui'
import { GTR, COLOR } from '@farewill/ui/tokens'
import { screenMin } from '@farewill/ui/helpers/responsive'

import steps from './steps'

const StepIllustration = styled(Image)`
  ${screenMin.s`
    width: 80%;
    display: block;
    margin: 0 auto;
  `}

  ${screenMin.m`
    width: 100%;
    margin: -${GTR.S};
  `};
`

const Steps = () => (
  <>
    <H decorative centered size="L" margin={[0, 'auto', 'XL']}>
      How it works
    </H>

    <Wrapper as="ol">
      {steps.map((step) => (
        <Wrapper as="li" key={step.name} padding={['XS', 0]}>
          <Grid background={COLOR.WHITE} bordered borderRadius="M" gap={0}>
            <Grid.Item
              spanFromM={7}
              spanFromL={6}
              padding={['M', 'M', 0]}
              paddingFromS={['L', 'L', 0]}
              paddingFromM="L"
              paddingFromL="XL"
            >
              <H context>{step.name}</H>
              <H decorative size="M">
                {step.title}
              </H>
              <P>{step.description}</P>
            </Grid.Item>
            <Grid.Item
              spanFromM={5}
              startColumnFromM={8}
              style={{ alignSelf: 'center' }}
            >
              <StepIllustration
                path={`illustrations/${step.illustration}`}
                width={240}
                widthFromS={480}
                widthFromM={360}
                widthFromL={500}
                stretch
              />
            </Grid.Item>
          </Grid>
        </Wrapper>
      ))}
    </Wrapper>
  </>
)

export default Steps
