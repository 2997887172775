import React from 'react'

import CounteredList from 'components/CounteredList'

import reasons from './reasons'

const Reasons = () => (
  <CounteredList
    title="Why make your wills together?"
    steps={reasons}
    illustrationMargin="-40px -20px"
    preventOrphanOnTitles={false}
  />
)

export default Reasons
