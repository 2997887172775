import React from 'react'
import styled from 'styled-components'
import { Image } from '@farewill/ui'
import { screenMin } from '@farewill/ui/helpers/responsive'

import HeroWithImage from 'components/HeroWithImage'
import LinkButton from 'components/LinkButton'
import { ONLINE_WILL_COUPLES_PRICE_IN_POUNDS, WILLS_SIGN_UP_PATH } from 'config'
import { formatPriceInPounds } from 'lib/formatting/pricing'

const StyledIllustration = styled(Image)`
  display: none;

  ${screenMin.m`
    display: block;
    margin: -10% -30%;
    width: 160%;
  `};

  ${screenMin.l`
    margin: -20%;
    width: 140%;
  `};

  ${screenMin.xl`
    margin: -10% -5% -15%;
  `};
`

const Hero = () => (
  <HeroWithImage
    title="Get wills sorted for you and your partner"
    subtitle={`Individual wills, completed in your own time. ${formatPriceInPounds(
      ONLINE_WILL_COUPLES_PRICE_IN_POUNDS
    )} when you pay together.`}
    additionalContent={
      <LinkButton
        productLink
        to={WILLS_SIGN_UP_PATH}
        preselectedService="online"
      >
        Let’s get started
      </LinkButton>
    }
    image={
      <StyledIllustration
        path="illustrations/couple-with-wills"
        width={560}
        widthFromL={600}
        stretch
      />
    }
  />
)

export default Hero
