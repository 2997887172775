import {
  ONLINE_WILL_PRICE_IN_POUNDS,
  ONLINE_WILL_COUPLES_PRICE_IN_POUNDS,
} from 'config'
import { formatPriceInPounds } from 'lib/formatting/pricing'

export default [
  {
    name: 'Step One',
    title: 'Sign up and invite your partner',
    description:
      'You’ll each need your own email address so you can create your own, personal accounts.',
    illustration: 'couples-invite',
  },
  {
    name: 'Step Two',
    title: 'Make your wills',
    description:
      'Most couples leave their estate to each other, but we can help you leave messages, personal gifts and funeral wishes too.',
    illustration: 'couples-with-laptops',
  },
  {
    name: 'Step Three',
    title: 'Pay and send for checking',
    description: `You can choose to pay together (${formatPriceInPounds(
      ONLINE_WILL_COUPLES_PRICE_IN_POUNDS
    )}) or separately (${formatPriceInPounds(
      ONLINE_WILL_PRICE_IN_POUNDS
    )} each). Our will specialists then check both of your wills to make sure everything is okay. If there are any issues, we’ll let you know right away.`,
    illustration: 'experts-checking-couples',
  },
  {
    name: 'Step Four',
    title: 'Print and sign',
    description:
      'Once we’ve checked over your wills, you can print and sign them with two witnesses. When you’re done, store them somewhere safe and make sure you tell your executors where they are.',
    illustration: 'couples-signing-wills',
  },
]
