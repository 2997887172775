import React from 'react'
import { Wrapper, H, P } from '@farewill/ui'

import LinkButton from 'components/LinkButton'
import { WILLS_SIGN_UP_PATH } from 'config'

const GetStartedOnline = () => (
  <Wrapper tag="section" centered>
    <H size="L" decorative>
      Try it for free today
    </H>

    <P maxWidthInColumns={6} margin={[0, 'auto']}>
      You can write your will online and get help from our friendly team before
      paying a penny. And if you like what you see, you can then pay to get it
      checked and approved by our will specialists.
    </P>

    <Wrapper margin={['L', 'auto', 0]}>
      <LinkButton
        productLink
        to={WILLS_SIGN_UP_PATH}
        preselectedService="online"
      >
        Let’s get started
      </LinkButton>
    </Wrapper>
  </Wrapper>
)

export default GetStartedOnline
