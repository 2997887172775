export default [
  {
    title: 'Make decisions together',
    description:
      'Farewill makes it easy to reflect each others wishes about who’ll look after your children or pets.',
    illustration: 'couples-arrows',
  },
  {
    title: 'Give each other certainty',
    description:
      'Make sure that your partner has everything they need, should the worst happen to either of you.',
    illustration: 'couples-thumbs-up',
  },
  {
    title: 'Leave personal messages',
    description:
      'With Farewill you have the flexibility to leave individual gifts, messages and funeral wishes.',
    illustration: 'couples-exchange',
  },
]
